/** @format */

import React from 'react';
import {Box} from '@chakra-ui/layout';
import Patterns from '../patterns';
import Layout from '../components/layout';
import {getContentNode} from '../lib/api/contentNodes';
import {getSeoFields} from '../lib/getSeoFields';
import {NextSeo} from 'next-seo';
import getFooter from '../lib/api/acf/footer';
import mappingFooter from '../patterns/Footer/mappingFooter';
// import getMainMenu from '../lib/api/acf/mainMenu';
import mappingMainMenu from '../patterns/MainMenu/mappingMainMenu';
import mappingNavBar from '../patterns/NavBar/mappingNavBar';
import {
	getMainMenu,
	getGlobalMainMenu,
	getParentSiteMainMenu,
} from '../lib/api/acf/navBarMenu';
import {identifySubdomain} from '../utils';
import DraftModeUI from '../components/DraftModeUI/Index';

function HomePage({post, seo, navBar, mainMenu, footer, subdomain, draftMode}) {
	if (!post) return null;
	return (
		<Layout
			seo={post.seo}
			navBar={navBar}
			footer={footer}
			subdomain={subdomain}
			currentUrl={'/'}>
			<NextSeo {...seo} />
			<Box>
				{draftMode && <DraftModeUI />}
				{post.pageLayout.flexibleContent.map((post: any, index: number) => {
					return (
						<Patterns
							fieldGroupName={post.__typename}
							post={post}
							subdomain={subdomain}
							key={`${post.fieldGroupName}_${index}`}
						/>
					);
				})}
			</Box>
		</Layout>
	);
}

export async function getStaticProps(context) {
	let { draftMode } = context;

	const id = `/`;
	const idType = 'URI';
	const post = await getContentNode(id, idType, draftMode);
	// console.log('getStaticProps post', post);
	const seo = !draftMode ? getSeoFields(post) : null;
	// console.log('SEO: ', seo);

	let footer = await getFooter('');
	footer = mappingFooter(footer);

	// Menus
	const globalMainMenu = await getGlobalMainMenu('');
	const mainMenu = await getMainMenu('');
	const parentSiteMenu = await getParentSiteMainMenu('');

	let navBar: any = [];

	if (mainMenu && globalMainMenu && parentSiteMenu) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		navBar = mappingNavBar({globalMainMenu, parentSiteMenu, mainMenu});
	}

	const subdomainStr = identifySubdomain(post?.link);

	if(!draftMode) {
		draftMode = false;
	}

	// const allPosts = preview
	// 	? await getAllPostsForHome(preview, subdomain)
	// 	: defaultPosts;

	return {
		props: {post, seo, navBar, mainMenu, footer, subdomain: subdomainStr, draftMode},
		revalidate: 150,
	};
}

export default HomePage;
